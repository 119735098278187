import React from "react";

export default function NaviBlur() {
    return (
        <>
            <div style={{opacity: 1, left: "calc(50.00000000000002% - 100% / 2)", order: 0, right: "unset",bottom: "-52px",flex: "none",position: "absolute",top: 0,width: "100%",zIndex: 1,}}>
                <div style={{position: "relative", width: "100%", height: "100%", borderRadius: "0px"}}>
                    <div
                        style={{position: "absolute", inset: "0px", zIndex: 1, backdropFilter: "blur(0.15625px)", maskImage: "linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 12.5%, rgb(0, 0, 0) 25%, rgba(0, 0, 0, 0) 37.5%)", borderRadius: "0px", pointerEvents: "none"}}></div>
                    <div
                        style={{position: "absolute", inset: "0px", zIndex: 2, backdropFilter: "blur(0.3125px)", maskImage: "linear-gradient(to top, rgba(0, 0, 0, 0) 12.5%, rgb(0, 0, 0) 25%, rgb(0, 0, 0) 37.5%, rgba(0, 0, 0, 0) 50%)", borderRadius: "0px", pointerEvents: "none"}}></div>
                    <div
                        style={{position: "absolute", inset: "0px", zIndex: 3, backdropFilter: "blur(0.625px)", maskImage: "linear-gradient(to top, rgba(0, 0, 0, 0) 25%, rgb(0, 0, 0) 37.5%, rgb(0, 0, 0) 50%, rgba(0, 0, 0, 0) 62.5%)", borderRadius: "0px", pointerEvents: "none"}}></div>
                    <div
                        style={{position: "absolute", inset: "0px", zIndex: 4, backdropFilter: "blur(1.25px)", maskImage: "linear-gradient(to top, rgba(0, 0, 0, 0) 37.5%, rgb(0, 0, 0) 50%, rgb(0, 0, 0) 62.5%, rgba(0, 0, 0, 0) 75%)", borderRadius: "0px", pointerEvents: "none"}}></div>
                    <div
                        style={{position: "absolute", inset: "0px", zIndex: 5, backdropFilter: "blur(2.5px)", maskImage: "linear-gradient(to top, rgba(0, 0, 0, 0) 50%, rgb(0, 0, 0) 62.5%, rgb(0, 0, 0) 75%, rgba(0, 0, 0, 0) 87.5%)", borderRadius: "0px", pointerEvents: "none"}}></div>
                    <div
                        style={{position: "absolute", inset: "0px", zIndex: 6, backdropFilter: "blur(5px)", maskImage: "linear-gradient(to top, rgba(0, 0, 0, 0) 62.5%, rgb(0, 0, 0) 75%, rgb(0, 0, 0) 87.5%, rgba(0, 0, 0, 0) 100%)", borderRadius: "0px", pointerEvents: "none"}}></div>
                    <div
                        style={{position: "absolute", inset: "0px", zIndex: 7, backdropFilter: "blur(10px)", maskImage: "linear-gradient(to top, rgba(0, 0, 0, 0) 75%, rgb(0, 0, 0) 87.5%, rgb(0, 0, 0) 100%)", borderRadius: "0px", pointerEvents: "none"}}></div>
                    <div
                        style={{position: "absolute", inset: "0px", zIndex: 8, backdropFilter: "blur(20px)", maskImage: "linear-gradient(to top, rgba(0, 0, 0, 0) 87.5%, rgb(0, 0, 0) 100%)", borderRadius: "0px", pointerEvents: "none"}}></div>
                </div>
            </div>
            <div style={{backgroundColor: "rgba(218, 218, 218, 0.5)", filter: "blur(20px)", opacity: 1, willChange: "auto",flex: "none",inset: "-42px 0 0",overflow: "hidden",position: "absolute",zIndex: 0}}></div>
        </>
    )
}